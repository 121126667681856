import { SelectItem } from "@/types/veutify";

export const getDstvPackagesAsSelectItem = (isAdmin: boolean = false) => {
    const items = [
        {
            text: "DSTV Comfam (9,500 FCFA)",
            value: "CB483",
        },
        {
            text: "DSTV Compact (13,500 FCFA)",
            value: "CB177",
        },
        {
            text: "DSTV Compact + Xtra View (16,500 FCFA)",
            value: "CB177-1",
        },
        {
            text: "DSTV Compact Plus (19,000 FCFA)",
            value: "CB281",
        },
        {
            text: "DSTV Compact Plus + Xtra View (22,000 FCFA)",
            value: "CB281-1",
        },
        {
            text: "DSTV Premium (26,500 FCFA)",
            value: "CB182",
        },
        {
            text: "DSTV Premium + Xtra View (30,000 FCFA)",
            value: "CB182-1",
        },
        {
            text: "Xtra View / HD PVR (4,500 FCFA)",
            value: "CB482",
        },
    ] as Array<SelectItem>;

    if (isAdmin) {
        items.push({
            text: "Custom Order",
            value: "CUSTOM",
        });
        items.push({
            text: "Admin Test (100 FCFA)",
            value: "TEST",
        });
    }

    return items;
};
